import { useEffect } from "react";
import "./ConditionsOfUse.scss";

export default function ConditionsOfUse() {
    useEffect(() => {
        document.title = "Conditions d'utilisation - Cédric Ciria"

        return () => {
            document.title = "Cédric Ciria"
        }
    }, [])

    return (
        <div id="ConditionsOfUse">
            <div className="conditions-of-use-container">
                <h1>Conditions d'utilisation</h1>
                <div className="conditions">
                    <h2>Définitions</h2>
                    <ul>
                        <li>"Site" désigne notre site de réservation en ligne accessible à l'adresse <a href="https://cedric-ciria.com">cedric-ciria.com</a>.</li>
                        <li>"Utilisateur" désigne toute personne qui accède et utilise notre site.</li>
                        <li>"Service" désigne l'ensemble des fonctionnalités et des services disponibles sur notre site.</li>
                    </ul>

                    <h2>Acceptation des CGU</h2>
                    <p>En utilisant notre site, vous acceptez de vous conformer à ces CGU. Si vous n'acceptez pas ces CGU, veuillez ne pas utiliser notre site.</p>

                    <h2>Collecte de données personnelles</h2>
                    <p>Nous respectons votre vie privée et nous nous engageons à protéger les données personnelles que vous nous fournissez. Notre politique de confidentialité décrit comment nous collectons, utilisons et protégeons vos données personnelles. En utilisant notre site, vous consentez à la collecte, à l'utilisation et à la divulgation de vos données personnelles conformément à notre politique de confidentialité.</p>

                    <h2>Utilisation du site</h2>
                    <p>Vous acceptez d'utiliser notre site conformément aux lois en vigueur et aux présentes CGU. Vous vous engagez à ne pas utiliser notre site à des fins illicites ou pour transmettre du contenu illégal, nuisible, diffamatoire, offensant, obscène ou autrement répréhensible.</p>

                    <h2>Propriété intellectuelle</h2>
                    <p>Tous les contenus présents sur notre site, y compris les textes, les images, les logos, les marques de commerce, les vidéos, les graphiques, sont protégés par des droits de propriété intellectuelle et sont la propriété exclusive de leurs détenteurs respectifs. Vous ne pouvez pas reproduire, distribuer, modifier, afficher publiquement ou utiliser de quelque manière que ce soit les contenus de notre site sans notre autorisation écrite préalable.</p>

                    <h2>Responsabilité</h2>
                    <p>Notre site est fourni "tel quel" et nous ne faisons aucune déclaration ni garantie quant à son fonctionnement, à son contenu ou à son accessibilité. Dans la mesure permise par la loi, nous déclinons toute responsabilité pour les dommages directs, indirects, consécutifs ou autres découlant de l'utilisation de notre site ou de l'impossibilité de l'utiliser.</p>

                    <h2>Liens vers des sites tiers</h2>
                    <p>Notre site peut contenir des liens vers des sites web tiers qui ne sont pas sous notre contrôle. Nous déclinons toute responsabilité quant au contenu ou aux pratiques de confidentialité de ces sites web tiers. L'utilisation de ces sites web est à vos propres risques.</p>

                    <h2>Modifications des CGU</h2>
                    <p>Nous nous réservons le droit de modifier ces CGU à tout moment. Les modifications prendront effet dès leur publication sur notre site. Il est de votre responsabilité de consulter régulièrement les CGU pour prendre connaissance des éventuelles modifications.</p>

                    <h2>Droit applicable et juridiction compétente</h2>
                    <p>Ces CGU sont régies par les lois en vigueur en France.</p>

                    <p>Si vous avez des questions ou des préoccupations concernant ces CGU, veuillez nous contacter à <a href="mailto:contact@cedric-ciria.com">contact@cedric-ciria.com</a>.</p>
                </div>
            </div>
        </div>
    )
}