import { useContext, useEffect, useState } from "react"
import "./Confirmation.scss"
import Context from "../../../Context"
import InviteToConnect from "../../../components/InviteToConnect/InviteToConnect"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import variables from "../../../globalVariables"

export default function Confirmation() {
    const { selectedHour, selectedDay, selectedMonth, selectedYear, selectedPrestation, jwt, availablePrestations, setAvailablePrestations, myInfos } = useContext(Context) as any

    const [infosOk, setInfosOk] = useState<boolean>(false) // If the user confirmed his infos
    const [errorText, setErrorText] = useState<string>("") // The error text to display
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false) // If the confirm button is loading
    const [waitingRedirect, setWaitingRedirect] = useState<boolean>(true) // If the user is waiting for the redirect to the confirmed page

    const navigation = useNavigate()
    useEffect(() => {
        if (!selectedHour || !selectedYear || selectedPrestation === -1) {
            setTimeout(() => {
                navigation("/rdv")
            }, 500)
        }

        if (!availablePrestations || availablePrestations.length === 0) {
            fetch(`${variables.apiURL}getPerformances`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(res => res.json()).then(data => {
                setAvailablePrestations(data.performances)
            }).catch(() => {
                setAvailablePrestations([])
            })
        }

        if (jwt && jwt.length > 0) {
            setWaitingRedirect(false)
        }
    }, [selectedHour, selectedYear, selectedPrestation, availablePrestations, jwt, navigation, setAvailablePrestations])


    const handleRDVConfirm = () => {
        if (jwt && jwt.length > 0 && myInfos && myInfos.name && myInfos.name.length > 0) {
            setConfirmLoading(true)
            fetch(`${variables.apiURL}CreateRDV`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${jwt}`
                },
                body: JSON.stringify({
                    date: dayjs().set("date", selectedDay).set("month", selectedMonth).set("year", selectedYear).set("hour", parseInt(selectedHour.split("h")[0])).set("minute", parseInt(selectedHour.split("h")[1])).set("second", 0).format("YYYY-MM-DD HH:mm:ss"), // format : YYYY-MM-DD HH:mm:ss
                    prestation: availablePrestations[selectedPrestation].name
                })
            }).then(res => {
                setConfirmLoading(false)
                if (res.ok) {
                    try {
                        res.json().then(data => {
                            if (data.rdvId) {
                                navigation(`/rdv/confirmed/${data.rdvId}`)
                            } else {
                                setErrorText("Une erreur est survenue")
                            }
                        })
                    } catch (_) {
                        setErrorText("Une erreur est survenue")
                    }
                } else {
                    try {
                        res.json().then(data => {
                            if (data.message) {
                                setErrorText(data.message)
                            } else {
                                setErrorText("Une erreur est survenue")
                            }
                        })
                    } catch (_) {
                        setErrorText("Une erreur est survenue")
                    }
                }
            }).catch(() => {
                setConfirmLoading(false)
                setErrorText("Une erreur est survenue")
            })
        } else {
            setErrorText("Une erreur est survenue")
        }
    }

    useEffect(() => {
        document.title = "Confirmation - Cédric Ciria"

        return () => {
            document.title = "Cédric Ciria"
        }
    }, [])
    return (
        <div id="Confirmation">
            {!waitingRedirect && jwt && jwt.length > 0 && myInfos && myInfos.name && myInfos.name.length > 0 && availablePrestations && availablePrestations[selectedPrestation] && availablePrestations[selectedPrestation].name && availablePrestations[selectedPrestation].price && availablePrestations[selectedPrestation].duration ?
                <div className="check-infos">
                    <h2>Confirmez vos informations</h2>
                    <div className="infos">
                        <div className="info">
                            <h3>Date</h3>
                            <p>{dayjs().set("date", selectedDay).set("month", selectedMonth).set("year", selectedYear).format("DD/MM/YYYY")}</p>
                        </div>
                        <div className="info">
                            <h3>Heure</h3>
                            <p>{selectedHour}</p>
                        </div>
                        <div className="info">
                            <h3>Durée</h3>
                            <p>{availablePrestations[selectedPrestation].duration} minutes</p>
                        </div>
                        <div className="info">
                            <h3>Prestation</h3>
                            <p>{availablePrestations[selectedPrestation].name} ({availablePrestations[selectedPrestation].price.toFixed(2).replace(".", ",")}€)</p>
                        </div>
                        <div className="info">
                            <h3>Nom</h3>
                            <p>{myInfos.name}</p>
                        </div>
                        <div className="info">
                            <h3>Email</h3>
                            <p>{myInfos.email}</p>
                        </div>
                        <div className="info">
                            <h3>Date de naissance</h3>
                            <p>{dayjs(myInfos.birthDate).format("DD/MM/YYYY")}</p>
                        </div>
                        <div className="info">
                            <h3>Numéro de téléphone</h3>
                            <p>{myInfos.phoneNumber}</p>
                        </div>
                        <div className="info">
                            <h3>Adresse de rendez-vous</h3>
                            <p>3 rue Jean Valmy Baysse, 33160, St-Médard-en-Jalles</p>
                        </div>
                        <div className="checkbox-confirm-container">
                            <input type="checkbox" id="infos-ok" checked={infosOk} onChange={() => { setInfosOk(!infosOk) }} />
                            <label htmlFor="infos-ok">Je confirme que ces informations sont exactes</label>
                        </div>
                        {errorText.length > 0 && <p className="error-text">{errorText}</p>}
                        <button className="confirm-rdv-btn" disabled={!infosOk || confirmLoading} onClick={handleRDVConfirm}>Je confirme mon RDV</button>
                    </div>
                </div>
                :
                <InviteToConnect redirectUrl="/rdv/confirmation" />
            }
        </div>
    )
}