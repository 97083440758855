import { Link } from "react-router-dom";
import "./Footer.scss";


export default function Footer() {
    return (
        <div id="Footer">
            <div className="footer-content">
                <div>
                    <h3>Adresse</h3>
                    <p>3 rue Jean Valmy Baysse</p>
                    <p>33160, St-Médard-en-Jalles</p>
                </div>
                <div>
                    <h3>Contact</h3>
                    <p>
                        tel: <a href="tel:0667509792">06 67 50 97 92</a>
                    </p>
                    <p>
                        mail: <a href="mailto:contact@cedric-ciria.com">contact@cedric-ciria.com</a>
                    </p>
                </div>
            </div>
            <div>
                <p>Site web réalisé par </p><Link to="https://mywebdream.com">My Web Dream</Link>
            </div>
            <div className="footer-legal">
                <Link to="/privacy-policy">Politique de confidentialité</Link>
                <Link to="/legal-mentions">Mentions légales</Link>
                <Link to="/cgu">CGU</Link>
            </div>
        </div>
    );
}