import { useContext, useEffect, useState } from "react";
import "./InviteToConnect.scss";
import variables from "../../globalVariables";
import Context from "../../Context";
import { Link, useNavigate } from "react-router-dom";

export default function InviteToConnect(props: { redirectUrl?: string }) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const [name, setName] = useState("")
    const [birthDate, setBirthDate] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [loading, setLoading] = useState(false)

    const [errorText, setErrorText] = useState("")
    const [redirectUrl, setRedirectUrl] = useState("/")

    const [connectionType, setConnectionType] = useState("Identification")
    const [displayConnectionType, setDisplayConnectionType] = useState("Identification")

    const [animationStage, setAnimationStage] = useState<"entering" | "exiting">("entering")

    const [conditionsAccepted, setConditionsAccepted] = useState(false)

    const { setJwt } = useContext(Context) as any

    const navigation = useNavigate()

    useEffect(() => {
        if (connectionType === displayConnectionType) return
        if (connectionType === "Identification") return
        setAnimationStage("exiting")
        setTimeout(() => {
            setErrorText("")
            setDisplayConnectionType(connectionType)
            setAnimationStage("entering")
        }, 300)
    }, [connectionType, displayConnectionType])

    useEffect(() => {
        if (props.redirectUrl) {
            setRedirectUrl("/redirect" + props.redirectUrl)
        }
    }, [props.redirectUrl])

    const handleSignupSubmit = (e: any) => {
        e.preventDefault()
        setErrorText("")

        // Check if password and password confirmation are the same
        if (password !== passwordConfirmation) {
            setErrorText("Les mots de passe ne correspondent pas")
            return
        }

        // Check email with a regex
        const emailRegex = new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")
        if (!emailRegex.test(email)) {
            setErrorText("L'email n'est pas valide")
            return
        }

        // Check if all fields are filled
        if (name.length === 0 || email.length === 0 || password.length === 0 || passwordConfirmation.length === 0 || birthDate.length === 0 || phoneNumber.length === 0) {
            setErrorText("Veuillez remplir tous les champs")
            return
        }

        // Check if the phone number is valid
        const phoneNumberRegex = new RegExp("^0[1-9]([-. ]?[0-9]{2}){4}$")
        if (!phoneNumberRegex.test(phoneNumber)) {
            setErrorText("Le numéro de téléphone n'est pas valide")
            return
        }

        // Check if the birth date is valid
        const birthDateRegex = new RegExp("^[0-9]{4}-[0-9]{2}-[0-9]{2}$")
        if (!birthDateRegex.test(birthDate)) {
            setErrorText("La date de naissance n'est pas valide")
            return
        }

        setLoading(true)
        fetch(`${variables.apiURL}Signup`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name,
                email,
                password,
                birthDate,
                phoneNumber
            })
        }).then(res => {
            setLoading(false)
            if (res.ok) {
                try {
                    res.json().then(data => {
                        if (data.jwt) {
                            navigation(redirectUrl)
                            setJwt(data.jwt)
                        } else {
                            setErrorText("Une erreur est survenue")
                        }
                    })
                } catch (_) {
                    setErrorText("Une erreur est survenue")
                }
            } else {
                try {
                    res.json().then(data => {
                        setErrorText(data.message)
                    })
                } catch (_) {
                    setErrorText("Une erreur est survenue")
                }
            }
        }).catch(() => {
            setLoading(false)
            setErrorText("Une erreur est survenue")
        })
    }

    const handleLoginSubmit = (e: any) => {
        e.preventDefault()
        setErrorText("")

        if (email.length === 0 || password.length === 0) {
            setErrorText("Veuillez remplir tous les champs")
            return
        }

        setLoading(true)
        fetch(`${variables.apiURL}Login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email,
                password
            })
        }).then(res => {
            setLoading(false)
            if (res.ok) {
                try {
                    res.json().then(data => {
                        if (data.jwt) {
                            navigation(redirectUrl)
                            setJwt(data.jwt)
                        } else {
                            setErrorText("Une erreur est survenue")
                        }
                    })
                } catch (_) {
                    setErrorText("Une erreur est survenue")
                }
            } else {
                try {
                    res.json().then(data => {
                        setErrorText(data.message)
                    })
                } catch (_) {
                    setErrorText("Une erreur est survenue")
                }
            }
        }).catch(() => {
            setLoading(false)
            setErrorText("Une erreur est survenue")
        })
    }

    return (
        <div id="InviteToConnect" className={animationStage}>
            <h2>{displayConnectionType}</h2>
            <div className="invite-to-connect-container">
                {displayConnectionType === "Connexion" ?
                    <>
                        <form onSubmit={handleLoginSubmit}>
                            <input type="hidden" autoComplete="username" />
                            <div className="field">
                                <label htmlFor="email">Email</label>
                                <input type="email" name="email" id="email" autoComplete="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                            </div>
                            <div className="field">
                                <label htmlFor="password">Mot de passe</label>
                                <input type="password" name="password" id="password" autoComplete="current-password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                            </div>
                            <button className="style-2" disabled={loading}>Se connecter</button>
                            {errorText.length > 0 && <p className="error-text">{errorText}</p>}
                            <Link to="/forgot-password">Mot de passe oublié ?</Link>
                        </form>
                        <p className="change-connection-type" onClick={() => { setConnectionType("Inscription") }}>Vous n'avez pas encore de compte ?</p>
                    </>
                    :
                    (displayConnectionType === "Inscription" ?
                        <>
                            <form onSubmit={handleSignupSubmit}>
                                <input type="hidden" autoComplete="username" />
                                <div className="field">
                                    <label htmlFor="name">Nom</label>
                                    <input type="text" name="name" id="name" autoComplete="name" value={name} onChange={(e) => { setName(e.target.value) }} />
                                </div>
                                <div className="field">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" name="email" id="email" autoComplete="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                </div>
                                <div className="field">
                                    <label htmlFor="password">Mot de passe</label>
                                    <input type="password" name="password" id="password" autoComplete="new-password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                </div>
                                <div className="field">
                                    <label htmlFor="password-confirmation">Confirmation du mot de passe</label>
                                    <input type="password" name="password-confirmation" id="password-confirmation" autoComplete="new-password" value={passwordConfirmation} onChange={(e) => { setPasswordConfirmation(e.target.value) }} />
                                </div>
                                <div className="field">
                                    <label htmlFor="birth-date">Date de naissance</label>
                                    <input type="date" name="birth-date" id="birth-date" value={birthDate} onChange={(e) => { setBirthDate(e.target.value) }} />
                                </div>
                                <div className="field">
                                    <label htmlFor="phone-number">Numéro de téléphone</label>
                                    <input type="tel" name="phone-number" id="phone-number" autoComplete="tel" value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }} />
                                </div>
                                <div className="field-inline">
                                    <input type="checkbox" name="conditions" id="conditions" checked={conditionsAccepted} onChange={(e) => { setConditionsAccepted(e.target.checked) }} />
                                    <label htmlFor="conditions">J'ai lu et j'accepte la <Link to="/privacy-policy">politique de confidentialité</Link> ainsi que les <Link to="/cgu">conditions générales d'utilisations</Link></label>
                                </div>
                                <button className="style-2" disabled={loading || !conditionsAccepted}>S'inscrire</button>
                            </form>
                            {errorText.length > 0 && <p className="error-text">{errorText}</p>}
                            <p className="change-connection-type" onClick={() => { setConnectionType("Connexion") }}>Vous avez déjà un compte ?</p>
                        </>
                        :
                        <>
                            <p>Vous avez déjà un compte ?</p>
                            <button className="style-2" onClick={() => { setConnectionType("Connexion") }}>Se connecter</button>
                            <div className="or-container">
                                <p>ou</p>
                            </div>
                            <p>Vous n'avez pas encore de compte ?</p>
                            <button onClick={() => { setConnectionType("Inscription") }}>S'inscrire</button>
                        </>
                    )
                }
            </div>
        </div>
    );
}