import { useContext, useEffect, useState } from "react";
import "./ChangePassword.scss";
import variables from "../../globalVariables";
import Context from "../../Context";
import { useNavigate } from "react-router-dom";

export default function ChangePassword() {
    const [passwordError, setPasswordError] = useState<string>("") // The error message of the password input
    const [passwordSuccess, setPasswordSuccess] = useState<string>("") // The success message of the password input
    const [oldPassword, setOldPassword] = useState<string>("") // The old password input
    const [password, setPassword] = useState<string>("") // The password input
    const [passwordConfirm, setPasswordConfirm] = useState<string>("") // The password confirmation input
    const [loadingChangePassword, setLoadingChangePassword] = useState<boolean>(false) // If the user is currently changing his password [true, false]
    const navigate = useNavigate()

    const { jwt } = useContext(Context) as { jwt: string }


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setPasswordError("")
        setPasswordSuccess("")

        if (oldPassword.length === 0) {
            setPasswordError("Veuillez entrer votre ancien mot de passe")
            return
        }

        if (password.length === 0) {
            setPasswordError("Veuillez entrer votre nouveau mot de passe")
            return
        }

        if (password !== passwordConfirm) {
            setPasswordError("Les mots de passe ne correspondent pas")
            return
        }
        setLoadingChangePassword(true)

        fetch(`${variables.apiURL}ChangePassword`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${jwt}`
            },
            body: JSON.stringify({
                oldPassword,
                newPassword: password
            })
        }).then(res => {
            setLoadingChangePassword(false)
            if (res.ok) {
                setPasswordSuccess("Votre mot de passe a bien été changé")
            } else {
                try {
                    res.json().then(data => {
                        setPasswordError(data.message)
                    })
                } catch (_) {
                    setPasswordError("Une erreur est survenue")
                }
                if (res.status === 401) {
                    navigate("/register")
                }
            }
        }).catch(() => {
            setLoadingChangePassword(false)
            setPasswordError("Une erreur est survenue")
        })
    }

    const handleOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOldPassword(e.target.value)
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }

    const handlePasswordConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirm(e.target.value)
    }

    useEffect(() => {
        document.title = "Changer de mot de passe - Cédric Ciria"

        return () => {
            document.title = "Cédric Ciria"
        }
    }, [])

    return (
        <div id="ChangePassword">
            <div className="change-password-container">
                <h1 className="change-pswd-title">Changer de mot de passe</h1>
                <form className="change-pswd-form" onSubmit={handleSubmit}>
                    <div className="change-pswd-form-input">
                        <label htmlFor="old-pswd">Ancien mot de passe</label>
                        <input type="password" id="old-pswd" autoComplete="current-password" value={oldPassword} onChange={handleOldPasswordChange} />
                    </div>
                    <div className="change-pswd-form-input">
                        <label htmlFor="new-pswd">Nouveau mot de passe</label>
                        <input type="password" id="new-pswd" autoComplete="new-password" value={password} onChange={handlePasswordChange} />
                    </div>
                    <div className="change-pswd-form-input">
                        <label htmlFor="new-pswd-confirm">Confirmer le nouveau mot de passe</label>
                        <input type="password" id="new-pswd-confirm" autoComplete="off" value={passwordConfirm} onChange={handlePasswordConfirmChange} />
                    </div>
                    {passwordError.length > 0 && <p className="change-pswd-error">{passwordError}</p>}
                    {passwordSuccess.length > 0 && <p className="change-pswd-success">{passwordSuccess}</p>}
                    <button disabled={loadingChangePassword} type="submit">Changer de mot de passe</button>
                </form>
            </div>
        </div>
    )
}