import { useEffect, useRef } from "react";
import "./DescriptionSection.scss";

export default function DescriptionSection(props: { children: any }) {
    const textRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Annimation when the component is visible
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("description-visible");
                }
            });
        });

        observer.observe(imageRef.current!);
        observer.observe(textRef.current!);

        return () => {
            observer.disconnect();
        }
    }, [])
    return (
        <div className={"DescriptionSection"}>
            <div ref={imageRef} className="image-container">
                {props.children[0]}
            </div>
            <div ref={textRef} className="text-container">
                {props.children[1]}
            </div>
        </div>
    )
}