import { useContext, useEffect, useRef } from "react";
import DescriptionSection from "../../components/DescriptionSection/DescriptionSection";
import YinYang from "../../svg/yin_yang";
import "./Home.scss"
import { Link } from "react-router-dom";
import ArrowBottom from "../../svg/arrow_bottom";
import Context from "../../Context";
import variables from "../../globalVariables";
import Text from "../../components/Text";

export default function Home() {
    const yinYangContainerRef = useRef<HTMLDivElement>(null)
    const discoverMoreRef = useRef<HTMLHeadingElement>(null)
    const aloneImageRef = useRef<HTMLDivElement>(null)
    const meetMeRef = useRef<HTMLDivElement>(null)

    const { texts, setTexts } = useContext(Context) as { texts: { id: number, value: string }[], setTexts: React.Dispatch<React.SetStateAction<{ id: number, value: string }[]>> }

    useEffect(() => {
        document.title = "Accueil - Cédric Ciria"
        const yinYangContainer = yinYangContainerRef.current
        const discoverMore = discoverMoreRef.current
        const aloneImage = aloneImageRef.current
        const meetMe = meetMeRef.current

        // Animation of the yin yang when it is in the viewport
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("element-visible")
                }
            })
        })

        if (yinYangContainer) {
            observer.observe(yinYangContainer)
        }

        if (discoverMore) {
            observer.observe(discoverMore)
        }

        if (aloneImage) {
            observer.observe(aloneImage)
        }

        if (meetMe) {
            observer.observe(meetMe)
        }

        fetch(`${variables.apiURL}GetTexts?page=/home`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            if (res.ok) {
                res.json().then(data => {
                    // Add the texts to the context if there is not a text with the same id (replace all \n by <br />)
                    setTexts(data.texts.map((text: { id: number, value: string }) => {
                        return { id: text.id, value: text.value.replaceAll("\n", "<br />") }
                    }))
                })
            }
        })
        return () => {
            document.title = "Cédric Ciria"
            observer.disconnect()
        }
    }, [setTexts])

    return (
        <div id="Home">
            <div id="landing">
                <h1 className="landing-title">Cédric Ciria</h1>
                <p>Praticien en médoucine traditionnelle chinoise</p>
                <ArrowBottom className="arrow-bottom" onClick={() => { window.scrollTo({ top: window.scrollY + 100, left: 0, behavior: "smooth" }) }} />
                <div className="landing-cover" />
                <img src="/img/img_3.jpg" alt="Médecine chinoise" className="landing-background" />
            </div>
            <div ref={yinYangContainerRef} id="yin-yang-container" className="animation-prepare">
                <div className="yin-yang-cover" />
                <YinYang className="yin-yang" />
            </div>
            <div className="descriptions-container">
                <DescriptionSection>
                    <img src="/img/img_1.jpg" alt="Acupression" />
                    <Text text={texts.find(text => text.id === 1)?.value} />
                </DescriptionSection>
                <DescriptionSection>
                    <img src="/img/img_2.jpg" alt="Acupression" />
                    <Text text={texts.find(text => text.id === 2)?.value} />
                </DescriptionSection>
            </div>
            <h2 className="discover-more animation-prepare" ref={discoverMoreRef}>Découvrez-en plus</h2>
            <div className="alone-image animation-prepare" ref={aloneImageRef}>
                <img src="/img/img_4.jpg" alt="Acupression" />
            </div>
            <div className="meet-me animation-prepare" ref={meetMeRef}>
                <Text text={texts.find(text => text.id === 3)?.value} />
                <Link to="/rdv"><button>Me rencontrer &gt;</button></Link>
            </div>
        </div>
    );
}