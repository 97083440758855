import { useContext, useEffect, useState } from "react";
import "./Rdv.scss"
// import dayjs from "dayjs";
import "dayjs/locale/fr"
import Context from "../../Context";
import variables from "../../globalVariables";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

export default function Rdv() {
    // const { selectedHour, setSelectedHour, selectedDay, setSelectedDay, selectedMonth, setSelectedMonth, selectedYear, setSelectedYear, selectedPrestation, setSelectedPrestation, availablePrestations, setAvailablePrestations, apiAvailable, setApiAvailable } = useContext(Context) as { selectedHour: string, setSelectedHour: any, selectedDay: number, setSelectedDay: any, selectedMonth: number, setSelectedMonth: any, selectedYear: number, setSelectedYear: any, selectedPrestation: number, setSelectedPrestation: any, availablePrestations: { name: string, price: number, duration: number }[], setAvailablePrestations: (prestations: { name: string, price: number, duration: number }[]) => void, apiAvailable: boolean, setApiAvailable: (available: boolean) => void }
    // const [calendarDays, setCalendarDays] = useState<number[]>([]) // All days of the calendar (1-31) or (1-30) or (1-28) or (1-29)
    // const [calendarStartingDay, setCalendarStartingDay] = useState<number>(0) // The day of the week of the first day of the calendar (0-6)
    // const [availableHours, setAvailableHours] = useState<string[][]>([]) // The available hours for the selected month and prestation
    // const [errorText, setErrorText] = useState<string>("") // The error text displayed to the user
    // const [hours, setHours] = useState<{ close: `${string}h${string}`, each: number[], interval: number, open: `${string}h${string}` } | null>(null) // The hours of the salon
    // const [unavailableHours, setUnavailableHours] = useState<string[]>([]) // The unavailable hours for the selected day (ex: ["10h00", "12h00", "11h00"]])

    // TEMPORARY FOR CONTACT FORM
    const [name, setName] = useState<string>("") // The name of the user
    const [email, setEmail] = useState<string>("") // The email of the user
    const [message, setMessage] = useState<string>("") // The message of the user
    const [mailLoading, setMailLoading] = useState<boolean>(false) // If the mail is loading or not
    const [messageError, setMessageError] = useState<string>("") // The error text displayed to the user
    const [messageSuccess, setMessageSuccess] = useState<string>("") // The success text displayed to the user

    // TEMPORARY FOR COMMS
    const [comment, setComment] = useState<string>("") // The comment of the user
    const [commentStars, setCommentStars] = useState<number>(5) // The stars of the comment of the user
    const [commentLoading, setCommentLoading] = useState<boolean>(false) // If the comment is loading or not
    const [comments, setComments] = useState<{ name: string, message: string, date: string, stars: number }[]>([]) // The comments of the user
    const [commentsLoading, setCommentsLoading] = useState<boolean>(false) // If the comments are loading or not
    const [commentsPage, setCommentsPage] = useState<number>(1) // The page of the comments
    const [commentError, setCommentError] = useState<string>("") // The error text displayed to the user
    const [commentSuccess, setCommentSuccess] = useState<string>("") // The success text displayed to the user
    const { jwt } = useContext(Context) as { jwt: string }

    // const navigation = useNavigate()

    // useEffect(() => {
    //     const today = dayjs()
    //     const daysInMonth = dayjs().set("month", selectedMonth).daysInMonth()

    //     if (selectedDay === 0) {
    //         setSelectedMonth(today.month())
    //         setSelectedYear(today.year())
    //     }

    //     dayjs.locale("fr")

    //     var newCalendarDays: number[] = []
    //     for (var i = 1; i <= daysInMonth; i++) {
    //         newCalendarDays.push(i)
    //     }

    //     setCalendarDays(newCalendarDays)
    //     setErrorText("")

    //     if (selectedYear !== 0) {
    //         fetch(`${variables.apiURL}GetUnavailableHours?month=${selectedMonth}&year=${selectedYear}&day=${selectedDay - 1}`, {
    //             method: "GET"
    //         }).then(res => {
    //             setApiAvailable(true)
    //             if (res.ok) {
    //                 try {
    //                     res.json().then(data => {
    //                         setUnavailableHours(data.unavailableHours)
    //                     })
    //                 } catch (_) {
    //                     setUnavailableHours([])
    //                 }
    //             }
    //         }).catch(() => {
    //             setApiAvailable(false)
    //             setUnavailableHours([])
    //         })
    //     }

    // }, [selectedDay, setAvailablePrestations, setSelectedMonth, selectedMonth, setSelectedYear, setAvailableHours, selectedYear, setApiAvailable])

    // useEffect(() => {
    //     fetch(`${variables.apiURL}getPerformances`, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     }).then(res => res.json()).then(data => {
    //         setApiAvailable(true)
    //         setAvailablePrestations(data.performances)
    //     }).catch(() => {
    //         setApiAvailable(false)
    //         setAvailablePrestations([])
    //     })
    // }, [setAvailablePrestations, setApiAvailable])


    // useEffect(() => {
    //     setSelectedHour("")
    // }, [selectedPrestation, setSelectedHour])

    // useEffect(() => {
    //     const today = dayjs(`${selectedYear}-${selectedMonth + 1}-1`)
    //     const daysInMonth = today.daysInMonth()
    //     var startingDay = today.startOf('month').day()

    //     // 1 should be 6
    //     if (startingDay === 0) {
    //         startingDay = 6
    //     } else {
    //         startingDay--
    //     }

    //     setCalendarStartingDay(startingDay)

    //     var newCalendarDays: number[] = []
    //     for (var i = 1; i <= daysInMonth; i++) {
    //         newCalendarDays.push(i)
    //     }
    //     setCalendarDays(newCalendarDays)
    //     setErrorText("")

    //     if (selectedYear !== 0) {
    //         fetch(`${variables.apiURL}getAvailableDays`, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json"
    //             },
    //             body: JSON.stringify({
    //                 month: selectedMonth,
    //                 year: selectedYear
    //             })
    //         }).then(res => res.json()).then(data => {
    //             setApiAvailable(true)
    //             setAvailableHours(data.availableDays)
    //         }).catch(() => {
    //             setApiAvailable(false)
    //             setAvailableHours([])
    //         })
    //         setSelectedPrestation(-1)
    //         setSelectedHour("")
    //     }
    // }, [selectedMonth, selectedYear, setSelectedPrestation, setSelectedHour, setApiAvailable])

    // useEffect(() => {
    //     fetch(`${variables.apiURL}GetHours`, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     }).then(res => res.json()).then(data => {
    //         setApiAvailable(true)
    //         setHours(data.hours)
    //     }).catch(() => {
    //         setApiAvailable(false)
    //         setHours(null)
    //     })
    // }, [setApiAvailable])

    // const handlePreviousMonth = () => {
    //     if (selectedMonth === 0) {
    //         setSelectedMonth(11)
    //         setSelectedYear((current: number) => current - 1)
    //         return
    //     }
    //     setSelectedMonth((current: number) => current - 1)
    //     setSelectedDay(-1)
    // }

    // const handleNextMonth = () => {
    //     if (selectedMonth === 11) {
    //         setSelectedMonth(0)
    //         setSelectedYear((current: number) => current + 1)
    //         return
    //     }
    //     setSelectedMonth((current: number) => current + 1)
    //     setSelectedDay(-1)
    // }

    // const handleDayClick = (selected: number) => {
    //     setSelectedHour("")
    //     setSelectedDay(selected)
    // }

    // const handleConfirmRDV = () => {
    //     if (selectedDay === 0) {
    //         setErrorText("Veuillez sélectionner un jour")
    //         return
    //     }

    //     if (dayjs(`${selectedYear}-${selectedMonth + 1}-${selectedDay}`).set("hour", Number(selectedHour.split("h")[0])).set("minute", Number(selectedHour.split("h")[1])).isBefore(dayjs())) {
    //         setErrorText("Veuillez sélectionner un jour et une heure dans le futur")
    //         return
    //     }

    //     if (selectedPrestation === -1) {
    //         setErrorText("Veuillez sélectionner une prestation")
    //         return
    //     }

    //     if (selectedHour === "") {
    //         setErrorText("Veuillez sélectionner une heure")
    //         return
    //     }

    //     setErrorText("")

    //     navigation("/rdv/confirmation")
    // }

    // const checkIfHourIsAvailable = (hour: string): boolean => {
    //     const hourNumber = Number(hour.split("h")[0])
    //     const minuteNumber = Number(hour.split("h")[1])
    //     const duration = availablePrestations[selectedPrestation].duration
    //     const hourToCheck = dayjs().set("hour", hourNumber).set("minute", minuteNumber)
    //     // If the hour + prestation duration is after the closing hour, we don't display it
    //     if (selectedPrestation !== -1 && hours && hours.close) {
    //         const closingHourNumber = Number(hours.close.split("h")[0])
    //         const closingMinuteNumber = Number(hours.close.split("h")[1])
    //         const closingHour = dayjs().set("hour", closingHourNumber).set("minute", closingMinuteNumber)
    //         if (hourToCheck.add(duration, "minute").isAfter(closingHour)) {
    //             return false
    //         }
    //     }
    //     // If the hour + prestation duration is in the break, we don't display it
    //     if (selectedPrestation !== -1 && hours && hours.each && hours.interval) {
    //         for (let i = 0; i < duration; i += hours.interval) {
    //             if (hours.each.includes(hourToCheck.add(i, "minute").hour())) {
    //                 return false
    //             }
    //         }
    //     }
    //     // If the hour + prestation duration is overlapping with an unavailable hour, we don't display it
    //     if (selectedPrestation !== -1 && unavailableHours && hours && hours.interval) {
    //         for (let i = 0; i < duration; i += hours.interval) {
    //             if (unavailableHours.includes(hourToCheck.add(i, "minute").format("HH[h]mm"))) {
    //                 return false
    //             }
    //         }
    //     }
    //     return true
    // }

    useEffect(() => {
        setCommentsLoading(true)
        fetch(`${variables.apiURL}getComments?page=${commentsPage}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            if (res.ok) {
                try {
                    res.json().then(data => {
                        if (data.comments) {
                            setComments(data.comments)
                        } else {
                            setComments([])
                        }
                    })
                } catch (_) {
                    setComments([])
                }
            } else {
                setComments([])
            }
        }).catch(() => {
            setComments([])
        }).finally(() => {
            setCommentsLoading(false)
        })
    }, [commentsPage])

    useEffect(() => {
        document.title = "Prise de rendez-vous - Cédric Ciria"

        return () => {
            document.title = "Cédric Ciria"
        }
    }, [])

    const handleContactSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (name === "") {
            setMessageError("Veuillez renseigner votre nom")
            return
        }

        if (email === "") {
            setMessageError("Veuillez renseigner votre email")
            return
        }

        if (message === "") {
            setMessageError("Veuillez renseigner votre message")
            return
        }

        if (!email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
            setMessageError("Veuillez renseigner un email valide")
            return
        }

        if (mailLoading) {
            return
        }

        setMessageError("")
        setMessageSuccess("")
        setMailLoading(true)

        fetch(`${variables.apiURL}Contact`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name,
                email,
                message
            })
        }).then((res) => {
            if (res.ok) {
                setMessageSuccess("Votre message a bien été envoyé")
                setName("")
                setEmail("")
                setMessage("")
            } else {
                setMessageError("Une erreur est survenue, veuillez réessayer plus tard")
            }
        }).catch(() => {
            setMessageError("Une erreur est survenue, veuillez réessayer plus tard")
        }).finally(() => {
            setMailLoading(false)
        })
    }

    const handleCommentSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!jwt || jwt === "") {
            setCommentError("Vous devez être connecté pour poster un commentaire")
            return
        }

        if (comment === "") {
            setCommentError("Veuillez écrire votre message")
            return
        }

        if (commentLoading) {
            return
        }

        setCommentError("")
        setCommentSuccess("")
        setCommentLoading(true)

        fetch(`${variables.apiURL}NewComment`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${jwt}`
            },
            body: JSON.stringify({
                comment,
                commentStars
            })
        }).then((res) => {
            if (res.ok) {
                setCommentSuccess("Votre commentaire a bien été posté")
            } else {
                try {
                    res.json().then(data => {
                        if (data.message) {
                            setCommentError(data.message)
                        } else {
                            setCommentError("Une erreur est survenue, veuillez réessayer plus tard")
                        }
                    })
                } catch (_) {
                    setCommentError("Une erreur est survenue, veuillez réessayer plus tard")
                }
            }
        }).catch(() => {
            setCommentError("Une erreur est survenue, veuillez réessayer plus tard")
        }).finally(() => {
            setCommentLoading(false)
        })
    }

    const handlePreviousPage = () => {
        if (commentsPage > 1) {
            setCommentsPage(commentsPage - 1)
        }
    }

    const handleNextPage = () => {
        if (comments.length === 10) {
            setCommentsPage(commentsPage + 1)
        }
    }

    return (
        <div id="Rdv">
            <div className="Rdv-container-temp">
                <h1>Disponible pour un rendez-vous ?</h1>
                <p>Appelez-nous au <a href="tel:+0667509792">06 67 50 97 92</a> pour prendre rendez-vous ou contactez-nous par mail à <a href="mailto:contact@cedric-ciria.com">contact@cedric-ciria.com</a></p>
                <form className="contact-form" onSubmit={handleContactSubmit}>
                    <h2>Formulaire de contact</h2>
                    <div className="input-container">
                        <label htmlFor="name">Nom</label>
                        <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="input-container">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="input-container">
                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" value={message} onChange={(e) => setMessage(e.target.value)} />
                    </div>
                    {messageError !== "" && <p className="text-error">{messageError}</p>}
                    {messageSuccess !== "" && <p className="text-success">{messageSuccess}</p>}
                    <button disabled={mailLoading} >Envoyer</button>
                </form>
                <h1>Commentaires</h1>
                <div className={"comments-container " + (commentsLoading ? "loading" : "")}>
                    {jwt && jwt.length > 0 ?
                        <form className="comment-form" onSubmit={handleCommentSubmit}>
                            <label htmlFor="comment">Rédigez un message</label>
                            <div className="stars-container">
                                {
                                    Array.from(Array(5).keys()).map((index) => {
                                        return (
                                            <img key={index} className="star" src={commentStars > index ? "/img/star_solid.svg" : "/img/star_outline.svg"} alt="star" onClick={() => setCommentStars(index + 1)} />
                                        )
                                    })
                                }
                            </div>
                            <textarea id="comment" name="comment" value={comment} onChange={(e) => setComment(e.target.value)} />
                            {commentError !== "" && <p className="text-error">{commentError}</p>}
                            {commentSuccess !== "" && <p className="text-success">{commentSuccess}</p>}
                            <button disabled={commentLoading} >Publier</button>
                        </form>
                        :
                        <Link className="connect-to-comment-btn" to="/me"><button>Connectez-vous pour laisser un commentaire</button></Link>
                    }
                    {
                        comments.length === 0 ?
                            (commentsPage === 1 ?
                                <p className="no-comment-yet">Aucun commentaire pour le moment, soyez le premier à commenter</p>
                                :
                                <p className="no-comment-yet">Aucun commentaire pour cette page</p>
                            )
                            :
                            comments.map((comment, index) => {
                                return (
                                    <div className="comment" key={index}>
                                        <div className="comment-header">
                                            <p className="comment-name">{comment.name}</p>
                                            <p className="comment-date">- {comment.date}</p>
                                        </div>
                                        <div className="comment-stars">
                                            {
                                                [1, 2, 3, 4, 5].map((star, index) => {
                                                    if (star <= comment.stars) {
                                                        return <img key={index} src="/img/star_solid.svg" alt="star" className="star" />
                                                    } else {
                                                        return <img key={index} src="/img/star_outline.svg" alt="star" className="star" />
                                                    }
                                                })
                                            }
                                        </div>
                                        <p className="comment-message">{comment.message}</p>
                                    </div>
                                )
                            })
                    }
                    {
                        ((comments.length > 0 && (comments.length === 10 || commentsPage > 1)) || commentsPage > 1) &&
                        <div className="comments-pagination">
                            <button onClick={handlePreviousPage} disabled={commentsPage === 1 || commentsLoading}>
                                &lt;
                            </button>
                            <p>Page {commentsPage}</p>
                            <button onClick={handleNextPage} disabled={comments.length < 10 || commentsLoading}>
                                &gt;
                            </button>
                        </div>
                    }
                </div>
            </div>
            {/* <div className={"Rdv-container " + (!apiAvailable ? "Rdv-container-unavailable" : "")}>
                <div className="api-unavailable-text">
                    <p>La prise de rendez-vous est actuellement indisponible</p>
                </div>
                <h1>Quand êtes-vous disponible ?</h1>
                <div className="calendar-container">
                    <div className="calendar">
                        <div className="calendar-header">
                            <div className="calendar-header-month">
                                <button className="calendar-header-month-previous style-2" onClick={handlePreviousMonth}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 3l-9 9 9 9" /></svg>
                                </button>
                                <h2>{dayjs().set("month", selectedMonth).format("MMMM") + " " + dayjs().set("year", selectedYear).format("YYYY")}</h2>
                                <button className="calendar-header-month-next style-2" onClick={handleNextMonth}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.5 3l9 9-9 9" /></svg>
                                </button>
                            </div>
                            <div className="calendar-header-days">
                                <div className="calendar-header-day">Lun</div>
                                <div className="calendar-header-day">Mar</div>
                                <div className="calendar-header-day">Mer</div>
                                <div className="calendar-header-day">Jeu</div>
                                <div className="calendar-header-day">Ven</div>
                                <div className="calendar-header-day">Sam</div>
                                <div className="calendar-header-day">Dim</div>
                            </div>
                        </div>
                        <div className="calendar-body">
                            {
                                [...Array(calendarStartingDay)].map((day, index) => {
                                    const dayClosed = availableHours && availableHours[0] && availableHours[0].length === 0
                                    return (
                                        <div key={index} className={"calendar-body-day no-day " + (dayClosed ? "closed" : "")}>
                                            <p>{""}</p>
                                        </div>
                                    )
                                })
                            }
                            {
                                calendarDays.map((day, index) => {
                                    const dayClosed = availableHours && availableHours[day - 1] && availableHours[day - 1].length === 0
                                    return (
                                        <div key={index} className={"calendar-body-day " + (!dayClosed ? "clickable " : "") + (day === selectedDay && !dayClosed ? "selected " : "") + (dayClosed ? "closed" : "")} onClick={() => { if (!dayClosed) { handleDayClick(day) } }}>
                                            <p>{day.toString()}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="performance-choice">
                    <h2>Choix de la prestation : </h2>
                    <div className="performance-choice-container">
                        {
                            !availablePrestations || availablePrestations.length === 0 ?
                                <p>Aucune prestation disponible pour le moment.</p>
                                :
                                availablePrestations.map(({ price, name, duration }, index) => {
                                    return (
                                        <div key={index} className={"performance-choice-container-performance clickable " + (index === selectedPrestation ? "selected" : "")} onClick={() => { setSelectedPrestation(index) }}>
                                            <p>{name} - {duration}min ({price.toFixed(2).replace(".", ",")}€)</p>
                                            <input type="radio" checked={index === selectedPrestation} readOnly />
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
                <div className="rdv-time-choice">
                    <h2>Choix de l'horaire : </h2>
                    <div className="rdv-time-choice-container">
                        {selectedDay === 0 ?
                            <p>Veuillez sélectionner un jour</p>
                            :
                            (availablePrestations && availablePrestations[selectedPrestation] && availableHours[selectedDay - 1] && availableHours[selectedDay - 1].length !== 0 ?
                                availableHours[selectedDay - 1].map((hour, index) => {
                                    if (!checkIfHourIsAvailable(hour)) {
                                        return null
                                    }
                                    return (
                                        <div key={index} className={"rdv-time-choice-container-hour clickable " + (hour === selectedHour ? "selected" : "")} onClick={() => { setSelectedHour(hour) }}>
                                            <p>{hour}</p>
                                            <input type="radio" checked={hour === selectedHour} readOnly />
                                        </div>
                                    )
                                }).filter((element) => element !== null).length === 0 ? <p>Aucune horaire de disponible pour cette prestation</p> : availableHours[selectedDay - 1].map((hour, index) => {
                                    if (!checkIfHourIsAvailable(hour)) {
                                        return null
                                    }
                                    return (
                                        <div key={index} className={"rdv-time-choice-container-hour clickable " + (hour === selectedHour ? "selected" : "")} onClick={() => { setSelectedHour(hour) }}>
                                            <p>{hour}</p>
                                            <input type="radio" checked={hour === selectedHour} readOnly />
                                        </div>
                                    )
                                }).filter((element) => element !== null)
                                : (availablePrestations && availablePrestations[selectedPrestation] ?
                                    <p>Aucune horaire de disponible pour cette journée</p>
                                    :
                                    <p>Veuillez sélectionner une prestation</p>))
                        }
                    </div>
                </div>
                <div className="confirm-rdv-container">
                    <button onClick={handleConfirmRDV}>Prendre un RDV</button>
                </div>
                {errorText ?
                    <div className="error-text-container">
                        <p>
                            {errorText}
                        </p>
                    </div>
                    : null}
            </div> */}
        </div >
    );
}