import { Link } from "react-router-dom";
import "./Header.scss";
import Profile from "../../svg/profile";
import { useContext, useEffect, useState } from "react";
import Context from "../../Context";

export default function Header() {
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false)
    const [mobileNavOpacityDisplay, setMobileNavOpacityDisplay] = useState(false)
    const [mobileNavOpacityActive, setMobileNavOpacityActive] = useState(false)

    const { myInfos } = useContext(Context) as { myInfos: { name: string, email: string, birthDate: string, phoneNumber: string } }

    const handleBurgerMenuClick = () => {
        setMobileMenuOpened(!mobileMenuOpened)
    }

    useEffect(() => {
        if (mobileMenuOpened) {
            setMobileNavOpacityDisplay(mobileMenuOpened)
            setTimeout(() => {
                setMobileNavOpacityActive(mobileMenuOpened)
            }, 100)
        } else {
            setMobileNavOpacityActive(mobileMenuOpened)
            setTimeout(() => {
                setMobileNavOpacityDisplay(mobileMenuOpened)
            }, 400)
        }
    }, [mobileMenuOpened])

    const closeMobileMenu = () => {
        setMobileMenuOpened(false)
    }

    return (
        <>
            <div id="Header" className={mobileMenuOpened ? "mobile-menu-active" : ""}>
                <div className="logo-container">
                    <Link to="/">
                        <img src="/img/logo.png" alt="Logo" />
                        <h1 className="logo-text">Cédric Ciria</h1>
                    </Link>
                </div>
                <ul>
                    <li className="clickable">
                        <Link to="/" className="big-font-size" onClick={closeMobileMenu}>Accueil</Link>
                    </li>
                    <li className="clickable">
                        <Link to="/rdv" className="big-font-size" onClick={closeMobileMenu}>Rendez-vous</Link>
                    </li>
                    <li className="clickable">
                        <Link to="/about" className="big-font-size" onClick={closeMobileMenu}>À propos</Link>
                    </li>
                </ul>
                <div className="profile-container">
                    <Link to="/me">
                        <Profile className="user-icon" />
                    </Link>
                </div>
                <div className="burger-menu">
                    <div className="burger-menu-container" onClick={handleBurgerMenuClick}>
                        <div className={"burger-menu-line " + (mobileMenuOpened ? "burger-menu-line-active" : "")} />
                        <div className={"burger-menu-line " + (mobileMenuOpened ? "burger-menu-line-active" : "")} />
                        <div className={"burger-menu-line " + (mobileMenuOpened ? "burger-menu-line-active" : "")} />
                    </div>
                </div>
            </div >
            <div className={"mobile-nav-touchable-opacity " + (mobileMenuOpened && mobileNavOpacityActive ? "mobile-nav-touchable-opacity-active " : "") + (mobileNavOpacityDisplay ? "mobile-nav-touchable-opacity-display" : "")} onClick={() => { setMobileMenuOpened(false) }} />
            <div className={"mobile-nav " + (mobileMenuOpened ? "mobile-nav-opened" : "")}>
                <Link to="/me" className="mobile-nav-title" onClick={closeMobileMenu}>
                    <Profile />
                    <h1>{myInfos.name ? myInfos.name : "Non connecté"}</h1>
                </Link>
                <ul>
                    <li className="clickable">
                        <Link to="/" className="big-font-size" onClick={closeMobileMenu}>Accueil</Link>
                    </li>
                    <li className="clickable">
                        <Link to="/rdv" className="big-font-size" onClick={closeMobileMenu}>Rendez-vous</Link>
                    </li>
                    <li className="clickable">
                        <Link to="/about" className="big-font-size" onClick={closeMobileMenu}>À propos</Link>
                    </li>
                </ul>
            </div>
        </>
    );
}