import { useEffect } from "react"
import "./LegalMentions.scss"

export default function LegalMentions() {
    useEffect(() => {
        document.title = "Mentions légales - Cédric Ciria"

        return () => {
            document.title = "Cédric Ciria"
        }
    }, [])

    return (
        <div id="LegalMentions">
            <div className="legal-mentions-container">
                <h1>Mentions légales</h1>
                <div className="mentions">
                    <h2>Nom</h2>
                    <p>Cédric Ciria</p>
                    <h2>Adresse</h2>
                    <p>3 rue Valmy baysse, 33160, St-Médard-en-Jalles</p>
                    <h2>Téléphone</h2>
                    <p>06 67 50 97 92</p>
                    <h2>Adresse e-mail</h2>
                    <p><a href="mailto:contact@cedric-ciria.com">contact@cedric-ciria.com</a></p>
                    <h2>Numéro de SIRET</h2>
                    <p>89027949000019</p>
                    <h2>Hébergeur</h2>
                    <p>HOSTINGER, dont le siège social est situé HOSTINGER INTERNATIONAL LTD, 61 Lordou Vironos Street, 6023 Larnaca, Chypre, joignable par le moyen suivant : <a rel="noreferrer" target="_BLANK" href="https://www.hostinger.fr/contact">https://www.hostinger.fr/contact</a></p>

                </div>
            </div>
        </div>
    )
}