import { useEffect, useState } from "react";
import "./Admin.scss";
import variables from "../../globalVariables";
import dayjs from "dayjs";
import AsyncSelect from 'react-select/async';

export default function Admin() {
    const [adminInfos, setAdminInfos] = useState<{ identifier: string, password: string }>({ identifier: "", password: "" })
    const [adminJwt, setAdminJwt] = useState<string>("")
    const [identificationError, setIdentificationError] = useState<string>("")
    const [identificationLoading, setIdentificationLoading] = useState<boolean>(false)

    const [nextRdvs, setNextRdvs] = useState<{ id: number, day: number, hour: `${string}h${string}`, month: number, year: number, prestation_name: string, price: number, username: string, email: string, phoneNumber: string, birthdate: `${number}/${number}/${number}`, duration: number }[]>([]) // The next rdvs
    const [nextRdvsPage, setNextRdvsPage] = useState<number>(1) // The page of the next rdv
    const [reloadNextRdvs, setReloadNextRdvs] = useState<boolean>(false)
    const [deletingRdv, setDeletingRdv] = useState<boolean>(false)

    const [nextClosedDays, setNextClosedDays] = useState<{ day: number, month: number, year: number }[]>([]) // The next closed days
    const [nextClosedDaysPage, setNextClosedDaysPage] = useState<number>(1) // The page of the next closed days
    const [reloadNextClosedDays, setReloadNextClosedDays] = useState<boolean>(false)
    const [deletingClosedDay, setDeletingClosedDay] = useState<boolean>(false)
    const [addClosedDayButtonText, setAddClosedDayButtonText] = useState<string>("Ajouter un jour fermé")

    const [addingClosedDay, setAddingClosedDay] = useState<boolean>(false)
    const [newDayClosesDate, setNewDayClosesDate] = useState<{ day: string, month: string, year: string }>({ day: dayjs().date().toString(), month: (dayjs().month() + 1).toString(), year: dayjs().year().toString() })
    const [newDayClosedError, setNewDayClosedError] = useState<string>("")
    const [newDayClosedSuccess, setNewDayClosedSuccess] = useState<string>("")
    const [addingClosedDayLoading, setAddingClosedDayLoading] = useState<boolean>(false)
    const [selectedPage, setSelectedPage] = useState<string>("")

    const [texts, setTexts] = useState<{ id: number, page: string, value: string }[]>([])
    const [currentTexts, setCurrentTexts] = useState<{ id: number, page: string, value: string }[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const [comments, setComments] = useState<{ name: string, date: string, message: string, stars: number, id: number }[]>([])
    const [commentsPage, setCommentsPage] = useState<number>(1)
    const [commentsLoading, setCommentsLoading] = useState<boolean>(false)
    const [reloadComments, setReloadComments] = useState<boolean>(false)

    useEffect(() => {
        // nofollow, noindex
        const meta = document.createElement('meta');
        meta.id = "robots-admin";
        meta.name = "robots";
        meta.content = "noindex, nofollow";
        document.getElementsByTagName('head')[0].appendChild(meta);

        if (localStorage.getItem("adminJwt")) {
            setAdminJwt(localStorage.getItem("adminJwt") as string)
        }

        return () => {
            const meta = document.getElementById("robots-admin");
            if (meta) {
                meta.remove();
            }
        }
    }, [])



    useEffect(() => {
        setCommentsLoading(true)
        fetch(`${variables.apiURL}getComments?page=${commentsPage}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            if (res.ok) {
                try {
                    res.json().then(data => {
                        if (data.comments) {
                            setComments(data.comments)
                        } else {
                            setComments([])
                        }
                    })
                } catch (_) {
                    setComments([])
                }
            } else {
                setComments([])
            }
        }).catch(() => {
            setComments([])
        }).finally(() => {
            setCommentsLoading(false)
        })
    }, [commentsPage, reloadComments])

    useEffect(() => {
        if (adminJwt && adminJwt.length > 0) {
            localStorage.setItem("adminJwt", adminJwt)
            fetch(`${variables.apiURL}CheckAdminConnected`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${adminJwt}`
                }
            }).then(res => {
                if (!res.ok) {
                    setAdminJwt("")
                }
            }).catch(() => {
                setAdminJwt("")
            })
        } else {
            localStorage.removeItem("adminJwt")
        }
    }, [adminJwt])

    useEffect(() => {
        if (adminJwt && adminJwt.length > 0) {
            fetch(`${variables.apiURL}GetClosedDays?page=${nextClosedDaysPage}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${adminJwt}`
                }
            }).then(res => {
                if (res.ok) {
                    try {
                        res.json().then(data => {
                            setNextClosedDays(data.closedDays)
                        })
                    } catch (_) {
                        setNextClosedDays([])
                    }
                } else {
                    setNextClosedDays([])
                }
            }).catch(() => {
                setNextClosedDays([])
            })
        }
    }, [nextClosedDaysPage, adminJwt, reloadNextClosedDays])

    useEffect(() => {
        if (adminJwt && adminJwt.length > 0) {
            fetch(`${variables.apiURL}GetNextRdvs?page=${nextRdvsPage}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${adminJwt}`
                }
            }).then(res => {
                if (res.ok) {
                    try {
                        res.json().then(data => {
                            setNextRdvs(data.rdvs)
                        })
                    } catch (_) {
                        setNextRdvs([])
                    }
                } else {
                    setNextRdvs([])
                }
            }).catch(() => {
                setNextRdvs([])
            })
        }
    }, [nextRdvsPage, adminJwt, reloadNextRdvs])

    const handleIdentificationForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIdentificationError("")

        if (identificationLoading) {
            return
        }

        if (adminInfos.identifier.length === 0 || adminInfos.password.length === 0) {
            setIdentificationError("Veuillez remplir tous les champs")
            return
        }

        setIdentificationLoading(true)

        fetch(`${variables.apiURL}AdminLogin`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(adminInfos)
        }).then(res => {
            if (res.ok) {
                try {
                    res.json().then(data => {
                        setAdminJwt(data.token)
                    })
                } catch (_) {
                    setIdentificationError("Une erreur est survenue")
                }
            } else {
                try {
                    res.json().then(data => {
                        if (data.message) {
                            setIdentificationError(data.message)
                        } else {
                            setIdentificationError("Une erreur est survenue")
                        }
                    })
                } catch (_) {
                    setIdentificationError("Une erreur est survenue")
                }
            }
            setIdentificationLoading(false)
        }).catch(() => {
            setIdentificationError("Une erreur est survenue")
            setIdentificationLoading(false)
        })
    }

    const handlePreviousCommentsPage = () => {
        if (commentsPage > 1) {
            setCommentsPage(commentsPage - 1)
        }
    }

    const handleNextCommentsPage = () => {
        if (comments.length === 10) {
            setCommentsPage(commentsPage + 1)
        }
    }

    const handlePreviousRdvsPage = () => {
        if (nextRdvsPage > 1) {
            setNextRdvsPage(nextRdvsPage - 1)
        }
    }

    const handleNextRdvsPage = () => {
        if (nextRdvs && nextRdvs.length >= 10) {
            setNextRdvsPage(nextRdvsPage + 1)
        }
    }

    const handlePreviousClosedDaysPage = () => {
        if (nextClosedDaysPage > 1) {
            setNextClosedDaysPage(nextClosedDaysPage - 1)
        }
    }

    const handleNextClosedDaysPage = () => {
        if (nextClosedDays && nextClosedDays.length >= 10) {
            setNextClosedDaysPage(nextClosedDaysPage + 1)
        }
    }

    const handleAddClosedDaySubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setNewDayClosedError("")
        setNewDayClosedSuccess("")

        if (newDayClosesDate.day.length === 0 || newDayClosesDate.month.length === 0 || newDayClosesDate.year.length === 0) {
            setNewDayClosedError("Veuillez remplir tous les champs")
            return
        }

        if (isNaN(parseInt(newDayClosesDate.day)) || isNaN(parseInt(newDayClosesDate.month)) || isNaN(parseInt(newDayClosesDate.year))) {
            setNewDayClosedError("Veuillez entrer des nombres")
            return
        }

        if (parseInt(newDayClosesDate.day) < 1 || parseInt(newDayClosesDate.day) > 31 || parseInt(newDayClosesDate.month) < 1 || parseInt(newDayClosesDate.month) > 12 || parseInt(newDayClosesDate.year) < dayjs().year()) {
            setNewDayClosedError("Veuillez entrer une date valide")
            return
        }

        // Check if the day exists in this month
        const date = dayjs().set("date", parseInt(newDayClosesDate.day)).set("month", parseInt(newDayClosesDate.month) - 1).set("year", parseInt(newDayClosesDate.year))
        if (date.date() !== parseInt(newDayClosesDate.day) || date.month() !== parseInt(newDayClosesDate.month) - 1 || date.year() !== parseInt(newDayClosesDate.year)) {
            setNewDayClosedError("Cette date n'existe pas")
            return
        }

        setAddingClosedDayLoading(true)

        fetch(`${variables.apiURL}AddClosedDay`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${adminJwt}`
            },
            body: JSON.stringify({ day: parseInt(newDayClosesDate.day), month: parseInt(newDayClosesDate.month), year: parseInt(newDayClosesDate.year) })
        }).then(res => {
            setAddingClosedDayLoading(false)
            if (res.ok) {
                setReloadNextClosedDays(!reloadNextClosedDays)
                setNewDayClosedSuccess("Le jour a bien été ajouté")
                setTimeout(() => {
                    setReloadNextRdvs(!reloadNextRdvs)
                }, 1000)
            } else {
                try {
                    res.json().then(data => {
                        if (data.message) {
                            setNewDayClosedError(data.message)
                        } else {
                            setNewDayClosedError("Une erreur est survenue, impossible d'ajouter ce jour")
                        }
                    })
                } catch (_) {
                    setNewDayClosedError("Une erreur est survenue, impossible d'ajouter ce jour")
                }
                if (res.status === 401) {
                    setAdminJwt("")
                }
            }
        }).catch(() => {
            setNewDayClosedError("Une erreur est survenue, impossible d'ajouter ce jour")
            setAddingClosedDayLoading(false)
        })
    }

    const handleDeleteRdv = (id: number) => {
        if (adminJwt && adminJwt.length > 0) {
            if (deletingRdv) {
                return
            }
            setDeletingRdv(true)
            fetch(`${variables.apiURL}GetRDVInfos`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${adminJwt}`
                },
                body: JSON.stringify({ id })
            }).then(res => {
                setDeletingRdv(false)
                if (res.ok) {
                    try {
                        res.json().then(data => {
                            if (window.confirm("Voulez-vous supprimer le RDV du " + dayjs(data.date).format("DD/MM/YYYY") + " à " + dayjs(data.date).format("HH[h]mm") + " ? Un mail sera envoyé au client pour l'en informer.")) {
                                setDeletingRdv(true)
                                fetch(`${variables.apiURL}DeleteRDV`, {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        "Authorization": `Bearer ${adminJwt}`
                                    },
                                    body: JSON.stringify({ id })
                                }).then(res => {
                                    setDeletingRdv(false)
                                    if (res.ok) {
                                        try {
                                            res.json().then(data => {
                                                if (data.message) {
                                                    alert(data.message)
                                                } else {
                                                    alert("Le RDV a bien été supprimé")
                                                }
                                                setReloadNextRdvs(!reloadNextRdvs)
                                            })
                                        } catch (_) {
                                            alert("Une erreur est survenue, impossible de supprimer ce RDV")
                                        }
                                    } else {
                                        alert("Une erreur est survenue, impossible de supprimer ce RDV")
                                        if (res.status === 401) {
                                            setAdminJwt("")
                                        }
                                    }
                                }).catch(() => {
                                    alert("Une erreur est survenue, impossible de supprimer ce RDV")
                                    setDeletingRdv(false)
                                })
                            }
                        })
                    } catch (_) {
                        alert("Une erreur est survenue, impossible de supprimer ce RDV")
                    }
                } else {
                    alert("Une erreur est survenue, impossible de supprimer ce RDV")
                    if (res.status === 401) {
                        setAdminJwt("")
                    }
                }
            }).catch(() => {
                setDeletingRdv(false)
                alert("Une erreur est survenue, impossible de supprimer ce RDV")
            })
        }
    }

    const handleAddClosedDay = () => {
        if (adminJwt && adminJwt.length > 0) {
            setAddingClosedDay(!addingClosedDay)
            if (addingClosedDay) {
                setAddClosedDayButtonText("Ajouter un jour fermé")
            } else {
                setAddClosedDayButtonText("Fermer")
            }
        }
    }

    const handleNewDayClosedDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewDayClosesDate({ ...newDayClosesDate, day: e.target.value })
    }

    const handleNewDayClosedMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewDayClosesDate({ ...newDayClosesDate, month: e.target.value })
    }

    const handleNewDayClosedYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewDayClosesDate({ ...newDayClosesDate, year: e.target.value })
    }

    const handleDeleteClosedDay = (day: number, month: number, year: number) => {
        if (deletingClosedDay) {
            return
        }

        if (adminJwt && adminJwt.length > 0) {
            if (window.confirm("Voulez-vous supprimer le jour fermé du " + dayjs().set("date", day).set("month", month).set("year", year).format("DD/MM/YYYY") + " ?")) {
                setDeletingClosedDay(true)
                fetch(`${variables.apiURL}DeleteClosedDay`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${adminJwt}`
                    },
                    body: JSON.stringify({ day, month, year })
                }).then(res => {
                    setDeletingClosedDay(false)
                    if (res.ok) {
                        try {
                            res.json().then(data => {
                                if (data.message) {
                                    alert(data.message)
                                } else {
                                    alert("Le jour fermé a bien été supprimé")
                                }
                                setReloadNextClosedDays(!reloadNextClosedDays)
                            })
                        } catch (_) {
                            alert("Une erreur est survenue, impossible de supprimer ce jour fermé")
                        }
                    } else {
                        alert("Une erreur est survenue, impossible de supprimer ce jour fermé")
                        if (res.status === 401) {
                            setAdminJwt("")
                        }
                    }
                }).catch(() => {
                    setDeletingClosedDay(false)
                    alert("Une erreur est survenue, impossible de supprimer ce jour fermé")
                })
            }
        }
    }

    useEffect(() => {
        loadPages()
    }, [])

    useEffect(() => {
        fetch(`${variables.apiURL}GetTexts?page=${selectedPage}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            if (res.ok) {
                res.json().then(data => {
                    // Add the texts to the context if there is not a text with the same id (replace all \n by <br />)
                    setTexts(data.texts.map((text: { id: number, value: string }) => {
                        return { id: text.id, page: selectedPage, value: text.value.replaceAll("\r", "") }
                    }))
                    setCurrentTexts(data.texts.map((text: { id: number, value: string }) => {
                        return { id: text.id, page: selectedPage, value: text.value.replaceAll("\r", "") }
                    }))
                })
            }
        })
    }, [selectedPage])

    async function loadPages(): Promise<string[] | false> {
        try {
            const textsPages = await fetch(`${variables.apiURL}GetTextsPages`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            if (textsPages.ok) {
                const pages = await textsPages.json()
                if (pages.pages) {
                    return pages.pages
                } else {
                    return false
                }
            }
        } catch (_) {
            return false
        }
        return false
    }

    const pagesLoadOptions = (inputValue: string, callback: (pages: { value: string, label: string }[]) => void) => {
        loadPages().then(pages => {
            if (pages) {
                if (inputValue.length === 0) {
                    const pagesOptions = pages.map(page => {
                        return { value: page, label: page }
                    })
                    callback(pagesOptions)
                    return
                }

                const filteredPages = pages.filter(page => page.toLowerCase().includes(inputValue.toLowerCase()))
                callback(filteredPages.map(page => {
                    return { value: page, label: page }
                }))
            }
        })
    }

    const handleDeleteComment = (commentId: number) => {
        if (commentsLoading) {
            return
        }

        if (adminJwt && adminJwt.length > 0) {
            setCommentsLoading(true)
            fetch(`${variables.apiURL}DeleteComment`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${adminJwt}`
                },
                body: JSON.stringify({ commentId })
            }).then(res => {
                if (res.ok) {
                    alert("Le commentaire a bien été supprimé")
                    setReloadComments(!reloadComments)
                } else {
                    alert("Une erreur est survenue, impossible de supprimer ce commentaire")
                    if (res.status === 401) {
                        setAdminJwt("")
                    }
                }
            }).catch(() => {
                alert("Une erreur est survenue, impossible de supprimer ce commentaire")
            }).finally(() => {
                setCommentsLoading(false)
            })
        }
    }

    return (
        <div id="Admin">
            <div className="admin-container">
                <h1>Administration</h1>
                {adminJwt && adminJwt.length > 0 ?
                    <>
                        <fieldset>
                            <legend><h3>Mes prochains rendez-vous</h3></legend>
                            <div className="next-rdvs-container">
                                {
                                    nextRdvs && nextRdvs.length > 0 ?
                                        <>{
                                            nextRdvs.map((rdv, index) => {
                                                return (
                                                    <div key={rdv.id} className="next-rdv">
                                                        <p>Le <strong>{dayjs().set("date", rdv.day).set("month", rdv.month).set("year", rdv.year).format("DD/MM/YYYY")}</strong> à <strong>{rdv.hour}</strong></p>
                                                        <p><strong>{rdv.prestation_name}</strong> ({rdv.duration} minutes) - {rdv.price}€</p>
                                                        <p>Avec <strong>{rdv.username}</strong> né le <strong>{rdv.birthdate}</strong></p>
                                                        <p>Email : <strong>{rdv.email}</strong></p>
                                                        <p>Téléphone : <strong>{rdv.phoneNumber}</strong></p>
                                                        <button disabled={deletingRdv} onClick={() => { handleDeleteRdv(rdv.id) }}>Supprimer ce RDV</button>
                                                    </div>
                                                )
                                            })
                                        }</>
                                        :
                                        <p className="no-rdv">Aucun rendez-vous à venir</p>
                                }
                            </div><div className="next-rdvs-pagination">
                                <button disabled={nextRdvsPage === 1} onClick={handlePreviousRdvsPage}>&lt;</button>
                                <button disabled={nextRdvs.length < 10} onClick={handleNextRdvsPage}>&gt;</button>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend><h3>Mes jours fermés</h3></legend>
                            <div className="next-closed-days-container">
                                {
                                    nextClosedDays && nextClosedDays.length > 0 ?
                                        <>{
                                            nextClosedDays.map((closedDay, index) => {
                                                return (
                                                    <div key={index} className="next-closed-day">
                                                        <p>Vous êtes fermé le <strong>{dayjs().set("date", closedDay.day).set("month", closedDay.month).set("year", closedDay.year).format("DD/MM/YYYY")}</strong></p>
                                                        <p className="top-right-cross" onClick={() => { handleDeleteClosedDay(closedDay.day, closedDay.month, closedDay.year) }}>X</p>
                                                    </div>
                                                )
                                            })
                                        }</>
                                        :
                                        <p className="no-closed-day">Aucun jour fermé à venir</p>
                                }
                            </div>
                            <div className="next-closed-days-pagination">
                                <button disabled={nextClosedDaysPage === 1} onClick={handlePreviousClosedDaysPage}>&lt;</button>
                                <button disabled={typeof nextClosedDays === "undefined" || nextClosedDays.length < 10} onClick={handleNextClosedDaysPage}>&gt;</button>
                            </div>
                            <button onClick={handleAddClosedDay}>{addClosedDayButtonText}</button>
                            {addingClosedDay &&
                                <form className="add-closed-day-form" onSubmit={handleAddClosedDaySubmit}>
                                    <fieldset>
                                        <legend><h3>Ajouter un jour fermé</h3></legend>
                                        <div className="field-container">
                                            <label htmlFor="day">Jour</label>
                                            <input type="number" name="day" id="day" min={1} max={31} value={newDayClosesDate.day} onChange={handleNewDayClosedDayChange} />
                                        </div>
                                        <div className="field-container">
                                            <label htmlFor="month">Mois</label>
                                            <input type="number" name="month" id="month" min={1} max={12} value={newDayClosesDate.month} onChange={handleNewDayClosedMonthChange} />
                                        </div>
                                        <div className="field-container">
                                            <label htmlFor="year">Année</label>
                                            <input type="number" name="year" id="year" min={dayjs().year()} value={newDayClosesDate.year} onChange={handleNewDayClosedYearChange} />
                                        </div>
                                        <p>Si des rendez-vous ont été pris pour le jour que vous vous apprêtez à fermer, ils seront supprimés et un mail sera envoyé aux clients pour les en informer.</p>
                                        <button disabled={addingClosedDayLoading}>Ajouter</button>
                                        {newDayClosedError.length > 0 && <p className="new-day-closed-error">{newDayClosedError}</p>}
                                        {newDayClosedSuccess.length > 0 && <p className="new-day-closed-success">{newDayClosedSuccess}</p>}
                                    </fieldset>
                                </form>
                            }
                        </fieldset>
                        <fieldset>
                            <legend><h3>Textes du site</h3></legend>
                            <AsyncSelect placeholder="Sélectionnez une page" inputId="admin-select-page-input" loadOptions={pagesLoadOptions} defaultOptions cacheOptions onChange={(e) => { setSelectedPage(e?.value as string) }} />
                            <div className="site-texts-container">
                                {selectedPage && <h4>Page : {selectedPage}</h4>}
                                {
                                    texts && texts.length > 0 ?
                                        <>{
                                            texts.find(text => text.page === selectedPage) ?
                                                texts.map((text, index) => {
                                                    if (text.page === selectedPage) {
                                                        return (
                                                            <div key={index} className="site-text">
                                                                <p>Paragraphe {index + 1} :</p>
                                                                <textarea disabled={loading} rows={6} value={currentTexts[index].value} onChange={e => {
                                                                    const newTexts = [...currentTexts]
                                                                    newTexts[index].value = e.target.value
                                                                    setCurrentTexts(newTexts)
                                                                }}>
                                                                </textarea>
                                                                <button disabled={currentTexts[index].value === texts[index].value || loading} onClick={() => {
                                                                    setLoading(true)
                                                                    fetch(`${variables.apiURL}UpdateText`, {
                                                                        method: "POST",
                                                                        headers: {
                                                                            "Content-Type": "application/json",
                                                                            "Authorization": `Bearer ${adminJwt}`
                                                                        },
                                                                        body: JSON.stringify({ id: currentTexts[index].id, value: currentTexts[index].value })
                                                                    }).then(res => {
                                                                        if (res.ok) {
                                                                            setTexts(texts.map(text => {
                                                                                if (text.id === currentTexts[index].id) {
                                                                                    return { ...text, value: currentTexts[index].value }
                                                                                }
                                                                                return text
                                                                            }))
                                                                            try {
                                                                                res.json().then(data => {
                                                                                    if (data.message) {
                                                                                        alert(data.message)
                                                                                    } else {
                                                                                        alert("Le texte a bien été modifié")
                                                                                    }
                                                                                    setReloadNextClosedDays(!reloadNextClosedDays)
                                                                                })
                                                                            } catch (_) {
                                                                                alert("Une erreur est survenue, impossible de modifier ce texte")
                                                                            }
                                                                        } else {
                                                                            alert("Une erreur est survenue, impossible de modifier ce texte")
                                                                            if (res.status === 401) {
                                                                                setAdminJwt("")
                                                                            }
                                                                        }
                                                                    }).catch(() => {
                                                                        alert("Une erreur est survenue, impossible de modifier ce texte")
                                                                    }).finally(() => {
                                                                        setLoading(false)
                                                                    })
                                                                }}>Enregistrer</button>
                                                            </div>
                                                        )
                                                    }
                                                    return null
                                                })
                                                :
                                                <p className="no-text">Aucun texte pour cette page</p>
                                        }</>
                                        :
                                        <p className="no-text">Aucune page sélectionnée</p>
                                }
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend><h3>Commentaires</h3></legend>
                            <div className="comments-container">
                                {
                                    comments.length === 0 ?
                                        (commentsPage === 1 ?
                                            <p className="no-comment-yet">Aucun commentaire pour le moment</p>
                                            :
                                            <p className="no-comment-yet">Aucun commentaire pour cette page</p>
                                        )
                                        :
                                        comments.map((comment, index) => {
                                            return (
                                                <div className="comment" key={index}>
                                                    <div className="comment-header">
                                                        <p className="comment-name">{comment.name}</p>
                                                        <p className="comment-date">- {comment.date}</p>
                                                    </div>
                                                    <div className="comment-stars">
                                                        {
                                                            [1, 2, 3, 4, 5].map((star, index) => {
                                                                if (star <= comment.stars) {
                                                                    return <img key={index} src="/img/star_solid.svg" alt="star" className="star" />
                                                                } else {
                                                                    return <img key={index} src="/img/star_outline.svg" alt="star" className="star" />
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                    <p className="comment-message">{comment.message}</p>
                                                    <button onClick={() => { handleDeleteComment(comment.id) }} disabled={commentsLoading}>Supprimer</button>
                                                </div>
                                            )
                                        })
                                }
                                {
                                    ((comments.length > 0 && (comments.length === 10 || commentsPage > 1)) || commentsPage > 1) &&
                                    <div className="comments-pagination">
                                        <button onClick={handlePreviousCommentsPage} disabled={commentsPage === 1 || commentsLoading}>
                                            &lt;
                                        </button>
                                        <p>Page {commentsPage}</p>
                                        <button onClick={handleNextCommentsPage} disabled={comments.length < 10 || commentsLoading}>
                                            &gt;
                                        </button>
                                    </div>
                                }
                            </div>
                        </fieldset>
                    </>
                    :
                    <form className="identification-form" onSubmit={handleIdentificationForm}>
                        <fieldset>
                            <legend><h3>Identification</h3></legend>
                            <div className="field-container">
                                <label htmlFor="identifier">Identifiant</label>
                                <input type="text" name="identifier" id="identifier" value={adminInfos.identifier} onChange={e => setAdminInfos({ ...adminInfos, identifier: e.target.value })} />
                            </div>
                            <div className="field-container">
                                <label htmlFor="password">Mot de passe</label>
                                <input type="password" name="password" id="password" value={adminInfos.password} onChange={e => setAdminInfos({ ...adminInfos, password: e.target.value })} />
                            </div>
                            {identificationError.length > 0 && <p className="identification-error">{identificationError}</p>}
                            <button disabled={identificationLoading}>Se connecter</button>
                        </fieldset>
                    </form>
                }
            </div>
        </div >
    )
}