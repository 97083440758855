import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home";
import Header from "./components/Header/Header";
import Rdv from "./pages/Rdv/Rdv";
import About from "./pages/About/About";
import { useEffect, useState } from "react";
import Footer from "./components/Footer/Footer";
import Context from "./Context";
import Confirmation from "./pages/Rdv/Confirmation/Confirmation";
import variables from "./globalVariables";
import Confirmed from "./pages/Rdv/Confirmed/Confirmed";
import Redirect from "./pages/Redirect/Redirect";
import Me from "./pages/Me/Me";
import Register from "./pages/Register/Register";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Admin from "./pages/Admin/Admin";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy/PrivacyPolicy";
import ConditionsOfUse from "./pages/Legal/ConditionsOfUse/ConditionsOfUse";
import LegalMentions from "./pages/Legal/LegalMentions/LegalMentions";

export default function App() {
    // Context values
    const [selectedHour, setSelectedHour] = useState<string>("") // The hour selected by the user
    const [selectedDay, setSelectedDay] = useState<number>(0) // The day selected by the user
    const [selectedMonth, setSelectedMonth] = useState<number>(0) // The month selected by the user
    const [selectedYear, setSelectedYear] = useState<number>(0) // The year selected by the user
    const [selectedPrestation, setSelectedPrestation] = useState<number>(-1) // The prestation selected by the user
    const [availablePrestations, setAvailablePrestations] = useState<{ name: string, price: number, duration: number }[]>([]) // The available prestations
    const [myInfos, setMyInfos] = useState<{ name: string, email: string, birthDate: string, phoneNumber: string }>({ name: "", email: "", birthDate: "", phoneNumber: "" }) // The infos of the user [name, email, birthDate, phoneNumber]
    const [jwt, setJwt] = useState<string>("") // The JWT token of the user
    const [apiAvailable, setApiAvailable] = useState<boolean>(true) // If the API is available or not [true, false]
    const [texts, setTexts] = useState<{ id: number, value: string }[]>([])

    const location = useLocation()
    const [displayLocation, setDisplayLocation] = useState(location)
    const [transitionStage, setTransitionStage] = useState('entering')

    useEffect(() => {
        if (location.pathname !== displayLocation.pathname) {
            setTransitionStage('exiting')
            setTimeout(() => {
                setDisplayLocation(location)
                window.scroll({ top: 0, left: 0, behavior: 'smooth' })
                setTransitionStage('entering')
            }, 300)
        }
    }, [location, displayLocation])

    useEffect(() => {
        if (localStorage.getItem("jwt")) {
            setJwt(localStorage.getItem("jwt") as string)
        }
    }, [])

    useEffect(() => {
        if (jwt && jwt.length > 0) {
            localStorage.setItem("jwt", jwt)
            fetch(`${variables.apiURL}GetMyInfos`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${jwt}`
                }
            }).then(res => {
                setApiAvailable(true)
                if (res.ok) {
                    try {
                        res.json().then(data => {
                            setMyInfos(data.user)
                        })
                    } catch (_) {
                        setMyInfos({ name: "", email: "", birthDate: "", phoneNumber: "" })
                    }
                } else {
                    setMyInfos({ name: "", email: "", birthDate: "", phoneNumber: "" })
                    if (res.status === 401) {
                        setJwt("")
                    }
                }
            }).catch(() => {
                setApiAvailable(false)
                setMyInfos({ name: "", email: "", birthDate: "", phoneNumber: "" })
            })
        } else {
            localStorage.removeItem("jwt")
            setMyInfos({ name: "", email: "", birthDate: "", phoneNumber: "" })
        }
    }, [jwt])
    return (
        <Context.Provider value={{ selectedHour, setSelectedHour, selectedDay, setSelectedDay, selectedMonth, setSelectedMonth, selectedYear, setSelectedYear, selectedPrestation, setSelectedPrestation, jwt, setJwt, availablePrestations, setAvailablePrestations, myInfos, apiAvailable, setApiAvailable, texts, setTexts }} >
            <Header />
            <div className={transitionStage} id="App">
                <Routes location={displayLocation}>
                    <Route path="/" element={<Home />} />
                    <Route path="/rdv" element={<Rdv />} />
                    <Route path="/rdv/confirmation" element={<Confirmation />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/rdv/confirmed/:id" element={<Confirmed />} />
                    <Route path="/redirect/*" element={<Redirect />} />
                    <Route path="/me" element={<Me />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:token" element={<ResetPassword />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/cgu" element={<ConditionsOfUse />} />
                    <Route path="/legal-mentions" element={<LegalMentions />} />
                    <Route path="*" element={<Home />} />
                </Routes>
            </div>
            <Footer />
        </Context.Provider>
    );
}
