import { useContext, useEffect } from "react"
import InviteToConnect from "../../components/InviteToConnect/InviteToConnect"
import "./Register.scss"
import Context from "../../Context"
import { useNavigate } from "react-router-dom"

export default function Register() {
    const { jwt } = useContext(Context) as { jwt: string }
    const navigate = useNavigate()
    useEffect(() => {
        if (jwt && jwt.length > 0) {
            setTimeout(() => {
                navigate("/me")
            }, 600)
        }
    }, [jwt, navigate])

    useEffect(() => {
        document.title = "Inscription - Cédric Ciria"
        return () => {
            document.title = "Cédric Ciria"
        }
    }, [])
    return (
        <div id="Register">
            <InviteToConnect redirectUrl="/me" />
        </div>
    )
}