import { useContext, useEffect, useState } from "react";
import Context from "../../Context";
import "./Me.scss";
import { Link, useNavigate } from "react-router-dom";
import variables from "../../globalVariables";
import dayjs from "dayjs";

export default function Me() {
    const { jwt, setJwt, myInfos } = useContext(Context) as { jwt: string, myInfos: { name: string, email: string, birthDate: string, phoneNumber: string }, setJwt: (jwt: string) => void }
    const navigate = useNavigate()
    const [nextRdvs, setNextRdvs] = useState<{ hour: string, day: number, month: number, year: number, name: string, price: number, duration: number }[]>([])
    const [myInfosState, setMyInfos] = useState<{ name: string, email: string, birthDate: string, phoneNumber: string }>(myInfos)
    const [personalInfosError, setPersonalInfosError] = useState<string>("")
    const [personalInfosSuccess, setPersonalInfosSuccess] = useState<string>("")
    const [updatingPersonalInfos, setUpdatingPersonalInfos] = useState<boolean>(false)
    const [reloadRdvs, setReloadRdvs] = useState<boolean>(false)

    useEffect(() => {
        document.title = "Mon compte - Cédric Ciria"

        return () => {
            document.title = "Cédric Ciria"
        }
    }, [])

    useEffect(() => {
        if (!jwt || jwt.length === 0) {
            setTimeout(() => {
                if (window.location.pathname === "/me") {
                    navigate("/register")
                }
            }, 600)
            return
        }

        fetch(`${variables.apiURL}GetMyRDVs`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${jwt}`
            }
        }).then(res => {
            if (res.ok) {
                try {
                    res.json().then(data => {
                        setNextRdvs(data.rdvs)
                    })
                } catch (_) {
                    // pass
                }
            } else {
                if (res.status === 401) {
                    navigate("/register")
                }
            }
        }).catch(() => {
            setJwt("")
        })

        setMyInfos(myInfos)
    }, [jwt, navigate, myInfos, reloadRdvs, setJwt])

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMyInfos({ ...myInfos, name: e.target.value })
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMyInfos({ ...myInfos, email: e.target.value })
    }

    const handleBirthDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMyInfos({ ...myInfos, birthDate: e.target.value })
    }

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMyInfos({ ...myInfos, phoneNumber: e.target.value })
    }

    const handleSavePersonalInfos = () => {
        setPersonalInfosError("")
        setPersonalInfosSuccess("")
        if (myInfosState.name.length === 0) {
            setPersonalInfosError("Veuillez entrer un nom")
            return
        }

        if (myInfosState.email.length === 0) {
            setPersonalInfosError("Veuillez entrer un email")
            return
        }

        if (myInfosState.birthDate.length === 0) {
            setPersonalInfosError("Veuillez entrer une date de naissance")
            return
        }

        if (myInfosState.phoneNumber.length === 0) {
            setPersonalInfosError("Veuillez entrer un numéro de téléphone")
            return
        }
        setUpdatingPersonalInfos(true)
        fetch(`${variables.apiURL}UpdateMyInfos`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${jwt}`
            },
            body: JSON.stringify({
                name: myInfosState.name,
                email: myInfosState.email,
                birthDate: myInfosState.birthDate,
                phoneNumber: myInfosState.phoneNumber
            })
        }).then(res => {
            setUpdatingPersonalInfos(false)
            if (res.ok) {
                setPersonalInfosSuccess("Vos informations ont bien été mises à jour")
                try {
                    res.json().then(data => {
                        setReloadRdvs(!reloadRdvs)
                    })
                } catch (_) {
                    // pass
                }
            } else {
                try {
                    res.json().then(data => {
                        setPersonalInfosError(data.message)
                    })
                } catch (_) {
                    setPersonalInfosError("Une erreur est survenue")
                }
                if (res.status === 401) {
                    navigate("/register")
                }
            }
        }).catch(() => {
            setUpdatingPersonalInfos(false)
            setPersonalInfosError("Une erreur est survenue")
            setJwt("")
        })
    }

    const handleLogout = () => {
        setJwt("")
        navigate("/")
    }

    const handleRdvDelete = (day: number, month: number, year: number, hour: string) => {
        if (window.confirm(`Êtes-vous sûr de vouloir supprimer votre rendez-vous du ${dayjs().set("date", day + 1).set("month", month).set("year", year).format("DD/MM/YYYY")} à ${hour} ?`)) {
            fetch(`${variables.apiURL}DeleteMyRDV`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${jwt}`
                },
                body: JSON.stringify({
                    day,
                    month,
                    year,
                    hour
                })
            }).then(res => {
                if (res.ok) {
                    setReloadRdvs(!reloadRdvs)
                } else {
                    alert("Une erreur est survenue")
                    if (res.status === 401) {
                        navigate("/register")
                    }
                }
            }).catch(() => {
                alert("Une erreur est survenue")
                setJwt("")
            })
        }
    }

    return (
        <div id="Me">
            {!jwt || jwt.length === 0 ? <h2 className="not-connected-title">Vous n'êtes pas connecté</h2> :
                <div className="my-account-container">
                    <h1>Mon compte</h1>
                    <fieldset className="next-rdv-container">
                        <legend><h2>Mes rendez-vous</h2></legend>
                        {
                            nextRdvs.length === 0 ? <p>Vous n'avez pas de rendez-vous de prévu</p> :
                                <>{
                                    nextRdvs.map((rdv, index) => {
                                        return (
                                            <div className="rdv-container" key={index}>
                                                <div className="date">
                                                    <p>Le <strong>{dayjs().set("date", rdv.day + 1).set("month", rdv.month).set("year", rdv.year).format("DD/MM/YYYY")}</strong> à <strong>{rdv.hour}</strong></p>
                                                </div>
                                                <div className="infos">
                                                    <p>{rdv.name} ({rdv.duration} minutes)</p>
                                                    <p>{rdv.price}€</p>
                                                </div>
                                                <p className="top-right-close" onClick={() => { handleRdvDelete(rdv.day, rdv.month, rdv.year, rdv.hour) }}>X</p>
                                            </div>
                                        )
                                    })
                                }
                                </>
                        }
                    </fieldset>
                    <fieldset className="personal-infos">
                        <legend><h2>Mes informations personnelles</h2></legend>
                        <div className="personal-info">
                            <p>Nom</p>
                            <input type="text" value={myInfosState.name} onChange={handleNameChange} />
                        </div>
                        <div className="personal-info">
                            <p>Email</p>
                            <input type="text" value={myInfosState.email} onChange={handleEmailChange} />
                        </div>
                        <div className="personal-info">
                            <p>Date de naissance</p>
                            <input type="date" value={dayjs(myInfosState.birthDate).format("YYYY-MM-DD")} onChange={handleBirthDateChange} />
                        </div>
                        <div className="personal-info">
                            <p>Numéro de téléphone</p>
                            <input type="text" value={myInfosState.phoneNumber} onChange={handlePhoneNumberChange} />
                        </div>
                        <button className="save-personal-infos" onClick={handleSavePersonalInfos} disabled={updatingPersonalInfos}>Sauvegarder mes informations</button>
                        {personalInfosError.length > 0 && <p className="personal-infos-error">{personalInfosError}</p>}
                        {personalInfosSuccess.length > 0 && <p className="personal-infos-success">{personalInfosSuccess}</p>}
                    </fieldset>
                    <fieldset className="account-safety">
                        <legend><h2>Sécurité du compte</h2></legend>
                        <p>Vous pouvez changer votre mot de passe ou vous déconnecter</p>
                        <Link to="/change-password" className="change-password"><button>Changer de mot de passe</button></Link>
                        <button className="logout" onClick={handleLogout}>Se déconnecter</button>
                    </fieldset>
                </div>
            }
        </div>
    )
}
