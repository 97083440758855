import { Link, useParams } from "react-router-dom"
import "./Confirmed.scss"
import Context from "../../../Context"
import { useContext, useEffect, useState } from "react"
import variables from "../../../globalVariables"
import InviteToConnect from "../../../components/InviteToConnect/InviteToConnect"
import dayjs from "dayjs"

export default function Confirmed() {
    const params = useParams()
    const { jwt, setJwt } = useContext(Context) as { jwt: string, setJwt: React.Dispatch<React.SetStateAction<string>> }

    const [rdvInfos, setRdvInfos] = useState<{ date: Date, prestation: string, price: number, duration: number }>({ date: new Date(), prestation: "", price: 0, duration: 0 })
    const [error, setError] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        if (jwt && jwt.length > 0) {
            fetch(`${variables.apiURL}GetRdvInfos`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${jwt}`
                },
                body: JSON.stringify({ id: params.id })
            }).then(res => {
                setLoading(false)
                if (res.ok) {
                    try {
                        res.json().then(data => {
                            var date = new Date(data.date)
                            setRdvInfos({ date: date, prestation: data.prestation, price: data.price, duration: data.duration })
                        })
                    } catch (_) {
                        setRdvInfos({ date: new Date(), prestation: "", price: 0, duration: 0 })
                        setError("Une erreur est survenue")
                    }
                } else {
                    try {
                        res.json().then(data => {
                            if (data.message && data.message.length > 0) {
                                setRdvInfos({ date: new Date(), prestation: "", price: 0, duration: 0 })
                                setError(data.message)
                            } else {
                                setRdvInfos({ date: new Date(), prestation: "", price: 0, duration: 0 })
                                setError("Une erreur est survenue")
                            }
                        })
                    } catch (_) {
                        setRdvInfos({ date: new Date(), prestation: "", price: 0, duration: 0 })
                        setError("Une erreur est survenue")
                    }

                    if (res.status === 401) {
                        setJwt("")
                    }
                }
            }).catch(() => {
                setLoading(false)
                setRdvInfos({ date: new Date(), prestation: "", price: 0, duration: 0 })
                setError("Une erreur est survenue")
            })
        }
    }, [jwt, setJwt, params])

    useEffect(() => {
        document.title = "Rendez-vous confirmé - Cédric Ciria"

        return () => {
            document.title = "Cédric Ciria"
        }
    }, [])

    return (
        <div id="Confirmed">
            {jwt && jwt.length > 0 ?
                <div className="confirmed-container">
                    {loading ?
                        <h2>Chargement en cours...</h2>
                        :
                        (error.length > 0 ?
                            <h2>{error}</h2>
                            :
                            <div className="confirmed-infos">
                                <h1>Votre rendez-vous est confirmé !</h1>
                                <div className="infos">
                                    <div className="info">
                                        <h3>Date</h3>
                                        <p>{dayjs(rdvInfos.date).format("DD/MM/YYYY")}</p>
                                    </div>
                                    <div className="info">
                                        <h3>Heure</h3>
                                        <p>{dayjs(rdvInfos.date).format("HH[h]mm")}</p>
                                    </div>
                                    <div className="info">
                                        <h3>Prestation</h3>
                                        <p>{rdvInfos.prestation} ({rdvInfos.price.toFixed(2).replace(".", ",")}€)</p>
                                    </div>
                                    <div className="info">
                                        <h3>Adresse de rendez-vous</h3>
                                        <p>3 rue Jean Valmy Baysse, 33160, St-Médard-en-Jalles</p>
                                    </div>
                                </div>
                                <div className="to-home-container">
                                    <Link to="/"><button>Retour à l'accueil</button></Link>
                                </div>
                            </div>
                        )
                    }
                </div>
                :
                <InviteToConnect redirectUrl="/redirect/rdv/confirmation" />
            }
        </div>
    )
}