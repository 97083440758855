import { useContext, useEffect } from "react";
import DescriptionSection from "../../components/DescriptionSection/DescriptionSection";
import "./About.scss";
import variables from "../../globalVariables";
import Context from "../../Context";
import Text from "../../components/Text";

export default function About() {

    const { texts, setTexts } = useContext(Context) as { texts: { id: number, value: string }[], setTexts: React.Dispatch<React.SetStateAction<{ id: number, value: string }[]>> }
    useEffect(() => {
        document.title = "À propos - Cédric Ciria"


        fetch(`${variables.apiURL}GetTexts?page=/about`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            if (res.ok) {
                res.json().then(data => {
                    // Add the texts to the context if there is not a text with the same id (replace all \n by <br />)
                    setTexts(data.texts.map((text: { id: number, value: string }) => {
                        return { id: text.id, value: text.value.replaceAll("\n", "<br />") }
                    }))
                })
            }
        })

        return () => {
            document.title = "Cédric Ciria"
        }
    }, [setTexts])
    return (
        <div id="About">
            <h1>À propos</h1>
            <DescriptionSection>
                <img src="/img/img_6.jpg" alt="Méditation" />
                <div>
                    <Text text={texts.find(text => text.id === 4)?.value} />
                </div>
            </DescriptionSection >
            <DescriptionSection>
                <img src="/img/img_5.jpg" alt="Médecine chinoise" />
                <div>
                    <Text text={texts.find(text => text.id === 5)?.value} />
                </div>
            </DescriptionSection>
        </div >
    );
}