import { useEffect, useState } from "react"

export default function Text(props: { text: string | undefined }) {
    const [text, setText] = useState<string>("")

    useEffect(() => {
        if (props.text) {
            // Autodetect emails
            let newText = props.text.replaceAll(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g, "<a href=\"mailto:$1\">$1</a>")
            // Autodetect links
            newText = newText.replaceAll(/(https?:\/\/[^\s]+)/g, "<a href=\"$1\">$1</a>")
            // Autodetect phone numbers (even if there are spaces or dashes each 2 or 3 digits)
            newText = newText.replaceAll(/([0-9]{2,3}[- ]?[0-9]{2,3}[- ]?[0-9]{2,3}[- ]?[0-9]{2,3}[- ]?[0-9]{2,3})/g, "<a href=\"tel:$1\">$1</a>")

            // All text between ** is bold
            newText = newText.replaceAll(/\*\*(.*?)\*\*/g, "<b>$1</b>")

            setText(newText)
        }
    }, [props.text])
    return (
        <p dangerouslySetInnerHTML={{ __html: text || "..." }}></p>
    )
}