import { useEffect, useState } from "react";
import "./ForgotPassword.scss";
import variables from "../../globalVariables";

export default function ForgotPassword() {
    const [email, setEmail] = useState<string>("") // The email of the user
    const [error, setError] = useState<string>("") // The error message to display
    const [success, setSuccess] = useState<string>("") // The success message to display
    const [loading, setLoading] = useState<boolean>(false) // If the form is loading or not

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }

    const handleForgotSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (loading) {
            return
        }
        setLoading(true)
        setError("")
        setSuccess("")

        fetch(`${variables.apiURL}ForgotPassword`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ email })
        }).then(res => {
            setLoading(false)
            if (res.ok) {
                try {
                    res.json().then(data => {
                        if (data.message) {
                            setSuccess(data.message)
                        } else {
                            setSuccess("Un email vous a été envoyé !")
                        }
                    })
                } catch (_) {
                    setError("Une erreur est survenue !")
                }
            } else {
                try {
                    res.json().then(data => {
                        if (data.message) {
                            setError(data.message)
                        } else {
                            setError("Une erreur est survenue !")
                        }
                    })
                } catch (_) {
                    setError("Une erreur est survenue !")
                }
            }
        }).catch(() => {
            setLoading(false)
            setError("Une erreur est survenue !")
        })
    }

    useEffect(() => {
        document.title = "Mot de passe oublié - Cédric Ciria"

        return () => {
            document.title = "Cédric Ciria"
        }
    }, [])
    return (
        <div id="ForgotPassword">
            <div className="forgot-password-container">
                <h1>Mot de passe oublié</h1>
                <form onSubmit={handleForgotSubmit}>
                    <div className="field-container">
                        <label htmlFor="email"><h3>Email</h3></label>
                        <input type="email" name="email" id="email" value={email} onChange={handleEmailChange} />
                    </div>
                    <button disabled={loading} type="submit">Envoyer</button>
                    {error && <p className="error">{error}</p>}
                    {success && <p className="success">{success}</p>}
                </form>
            </div>
        </div>
    );
}