import { useNavigate, useParams } from "react-router-dom";
import "./Redirect.scss"

export default function Redirect() {
    const params = useParams()
    const navigation = useNavigate()

    if (typeof params["*"] === "string") {
        setTimeout(() => {
            navigation("/" + params["*"])
        }, 500)
    }
    return (
        <div id="Redirect">
            <h1>Chargement en cours...</h1>
        </div>
    )
}