import { useEffect, useState } from "react";
import "./ResetPassword.scss";
import variables from "../../globalVariables";


export default function ResetPassword() {
    const [password, setPassword] = useState<string>("")
    const [passwordConfirm, setPasswordConfirm] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [success, setSuccess] = useState<string>("")

    const handleResetSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setError("")
        setSuccess("")

        if (password !== passwordConfirm) {
            setError("Les mots de passes ne correspondent pas")
            return
        }

        setLoading(true)

        fetch(`${variables.apiURL}ResetPassword`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: window.location.pathname.split("/")[2],
                password
            })
        }).then(res => {
            if (res.ok) {
                try {
                    res.json().then(data => {
                        if (data.message) {
                            setSuccess(data.message)
                        } else {
                            setSuccess("Votre mot de passe a bien été modifié")
                        }
                    })
                } catch (_) {
                    setSuccess("Votre mot de passe a bien été modifié")
                }
            } else {
                try {
                    res.json().then(data => {
                        if (data.message) {
                            setError(data.message)
                        } else {
                            setError("Une erreur est survenue")
                        }
                    })
                } catch (_) {
                    setError("Une erreur est survenue")
                }
            }
            setLoading(false)
        }).catch(() => {
            setError("Une erreur est survenue")
            setLoading(false)
        })
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }

    const handlePasswordConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirm(e.target.value)
    }

    useEffect(() => {
        document.title = "Mot de passe oublié - Cédric Ciria"
        return () => {
            document.title = "Cédric Ciria"
        }
    }, [])
    return (
        <div id="ResetPassword">
            <div className="reset-password-container">
                <h1>Mot de passe oublié</h1>
                <form onSubmit={handleResetSubmit}>
                    <div className="field-container">
                        <label htmlFor="password"><h3>Mot de passe</h3></label>
                        <input type="password" name="password" id="password" value={password} onChange={handlePasswordChange} />
                    </div>
                    <div className="field-container">
                        <label htmlFor="password-confirm"><h3>Confirmation du mot de passe</h3></label>
                        <input type="password" name="password-confirm" id="password-confirm" value={passwordConfirm} onChange={handlePasswordConfirmChange} />
                    </div>
                    <button disabled={loading} type="submit">Envoyer</button>
                    {error && <p className="error">{error}</p>}
                    {success && <p className="success">{success}</p>}
                </form>
            </div>
        </div>
    );
}